<template>
<div class="app-wrapper">

    <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">

            <h1 class="app-page-title">My Account</h1>
            <div class="row gy-4">
                <div class="col-12 col-md-7">
                    <div class="app-card app-card-account shadow-sm d-flex flex-column align-items-start">
                        <div class="app-card-header p-3 border-bottom-0">
                            <div class="row align-items-center gx-3">
                                <div class="col-auto">
                                    <div class="app-icon-holder">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-person" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                        </svg>
                                    </div>
                                    <!--//icon-holder-->

                                </div>
                                <!--//col-->
                                <div class="col-auto">
                                    <h4 class="app-card-title">Profile</h4>
                                </div>
                                <!--//col-->
                            </div>
                            <!--//row-->
                        </div>
                        <!--//app-card-header-->
                        <div class="app-card-body px-4 w-100">
                            <div class="item border-bottom py-3">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col-auto">
                                        <div class="item-label mb-2"><strong>Photo</strong></div>
                                        <div class="item-data">
                                            <div v-if="user.no_hp != null" id="user-dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">
                                                <img v-bind:src="link+'storage/profile/' + user.foto" alt="user profile" style="width:100px; height:100px" />
                                            </div>
                                            <div v-else id="user-dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" style="width:100px; height:100px">
                                                <img src="assets/images/user.png" alt="user profile" style="width:100px; height:100px">
                                            </div>
                                        </div>
                                    </div>
                                    <!--//col-->
                                    <!-- <div class="col text-end">
                                        <a class="btn-sm app-btn-secondary" href="#">Change</a>
                                    </div> -->
                                    <!--//col-->
                                </div>
                                <!--//row-->
                            </div>
                            <!--//item-->
                            <div class="item border-bottom py-3">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col-auto">
                                        <div class="item-label"><strong>Nama</strong></div>
                                        <div class="item-data">{{ user.nama }}</div>
                                    </div>
                                    <!--//col-->
                                    <!-- <div class="col text-end">
                                        <a class="btn-sm app-btn-secondary" href="#">Change</a>
                                    </div> -->
                                    <!--//col-->
                                </div>
                                <!--//row-->
                            </div>
                            <!--//item-->
                            <div class="item border-bottom py-3">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col-auto">
                                        <div class="item-label"><strong>Email</strong></div>
                                        <div class="item-data">{{ user.email }}</div>
                                    </div>
                                    <!--//col-->
                                    <!-- <div class="col text-end">
                                        <a class="btn-sm app-btn-secondary" href="#">Change</a>
                                    </div> -->
                                    <!--//col-->
                                </div>
                                <!--//row-->
                            </div>
                            <div class="item border-bottom py-3">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col-auto">
                                        <div class="item-label"><strong>Nomor Handphone</strong></div>
                                        <div class="item-data">{{ user.no_hp }}</div>
                                    </div>
                                    <!--//col-->
                                    <!-- <div class="col text-end">
                                        <a class="btn-sm app-btn-secondary" href="#">Change</a>
                                    </div> -->
                                    <!--//col-->
                                </div>
                                <!--//row-->
                            </div>
                            <div class="item border-bottom py-3">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col-auto">
                                        <div class="item-label"><strong>Sebagai</strong></div>
                                        <div class="item-data text-capitalize">{{ user.role }}</div>
                                    </div>
                                    <!--//col-->
                                    <!-- <div class="col text-end">
                                        <a class="btn-sm app-btn-secondary" href="#">Change</a>
                                    </div> -->
                                    <!--//col-->
                                </div>
                                <!--//row-->
                            </div>
                            <!--//item-->
                            <div class="item border-bottom py-3">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col-auto">
                                        <div class="item-label"><strong>Alamat</strong></div>
                                        <div class="item-data">
                                            {{ user.alamat }}
                                        </div>
                                    </div>
                                    <!--//col-->
                                    <!-- <div class="col text-end">
                                        <a class="btn-sm app-btn-secondary" href="#">Change</a>
                                    </div> -->
                                    <!--//col-->
                                </div>
                                <!--//row-->
                            </div>
                        </div>
                        <!--//app-card-body-->
                        <div class="app-card-footer p-4 mt-auto">
                            <a @click="clickEdit" class="btn app-btn-secondary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Ubah Profile</a>
                            <!-- Modal -->
                            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="staticBackdropLabel">Ubah Profile</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="row g-3">
                                                <div class="col-md-12">
                                                    <label for="inputEmail4" class="form-label">Nama Karyawan</label>
                                                    <input type="text" v-model="namaEdit" :class="editMessage.nama == null ? 'form-control' : 'form-control is-invalid'" id="inputNama">
                                                    <div style="color:red;" v-if="editMessage.nama">
                                                        {{ editMessage.nama[0] }}
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="inputPassword4" class="form-label">Nomor HP</label>
                                                    <input type="text" v-model="nohpEdit" :class="editMessage.nohp == null ? 'form-control' : 'form-control is-invalid'" id="inputBarcode">
                                                    <div style="color:red;" v-if="editMessage.nohp">
                                                        {{ editMessage.nohp[0] }}
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <label for="inputPassword4" class="form-label">Email</label>
                                                    <input type="text" v-model="emailEdit" :class="editMessage.email == null ? 'form-control' : 'form-control is-invalid'" id="inputStok">
                                                    <div style="color:red;" v-if="editMessage.email">
                                                        {{ editMessage.email[0] }}
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <label for="inputRak" class="form-label">Alamat</label>
                                                    <input type="text" v-model="alamatEdit" :class="editMessage.alamat == null ? 'form-control' : 'form-control is-invalid'" id="inputRak">
                                                    <div style="color:red;" v-if="editMessage.alamat">
                                                        {{ editMessage.alamat[0] }}
                                                    </div>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="formFile" class="form-label">Foto</label>
                                                    <input class="form-control pt-0 pb-0" type="file" @change="handleFileUpload( $event )" id="formFile" style="height: 2rem">
                                                    <p class="mt-2" v-if="preview">Preview</p>
                                                    <img v-if="preview" :src="preview" class="img-fluid" style="widyh:100px; height:100px" />
                                                    <!-- <img v-if="preview" :src="preview" class="img-fluid" style="widyh:100px; height:100px" /> -->
                                                                    <div v-if="edited" class="mt-2">
                                                                        <img v-if="preview==''&&user.foto!=''" class="img-fluid" v-bind:src="link+'storage/profile/' + user.foto" style="width: 100px; height: 100px" />
                                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                            <button type="button" class="btn btn-primary text-white" @click="editForm">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--//app-card-footer-->

                    </div>
                    <!--//app-card-->
                </div>
                  <div class="col-12 col-md-5 mb-2" style="height:380px">
                    <div class="app-card app-card-account shadow-sm d-flex flex-column align-items-start">
                        <div class="app-card-header p-3 border-bottom-0">
                            <div class="row align-items-center gx-3">
                                <div class="col-auto">
                                    <div class="app-icon-holder">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-person" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                        </svg>
                                    </div>
                                    <!--//icon-holder-->

                                </div>
                                <!--//col-->
                                <div class="col-auto">
                                    <h4 class="app-card-title">Ganti Password</h4>
                                </div>
                                <!--//col-->
                            </div>
                            <!--//row-->
                        </div>
                        <!--//app-card-header-->
                        <div class="app-card-body px-4 w-100">
                            <div class="row g-3">
                                <div class="col-md-12">
                                    <label for="inputEmail4" class="form-label">Password lama</label>
                                    <input type="text" v-model="pw_lama" :class="editPw.pw_lama == null ? 'form-control' : 'form-control is-invalid'" id="inputNama">
                                    <div style="color:red;" v-if="editPw.pw_lama">
                                        {{ editPw.pw_lama[0] }}
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <label for="inputPassword4" class="form-label">Password baru</label>
                                    <input type="text" v-model="pw_baru" :class="editPw.pw_baru == null ? 'form-control' : 'form-control is-invalid'" id="inputBarcode">
                                    <div style="color:red;" v-if="editPw.pw_baru">
                                        {{ editPw.pw_baru[0] }}
                                    </div>
                                </div>
                                <div class="col-12">
                                    <a @click="editpw" class="btn app-btn-secondary">Ubah Password</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!--//app-card-->
                </div>
            </div>
            <!--//row-->

        </div>
        <!--//container-fluid-->
    </div>
    <!--//app-content-->

</div>
<!--//app-wrapper-->
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            user: [],
            link: this.globalApiUrl,
            loading: false,
            loggedIn: localStorage.getItem('loggedIn'),
            token: localStorage.getItem('token'),
            namaEdit: '',
            nohpEdit: '',
            alamatEdit: '',
            emailEdit: '',
            pw_lama: '',
            pw_baru: '',
            editMessage: [],
            editPw: [],
            file: undefined,
            preview: '',
            edited:false,
        }
    },
    created() {
        if (!this.loggedIn) {
            if (!this.token) {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
                return this.$router.push({
                    name: 'login.index'
                })
            } else {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
            }
        } else {
            this.getResults();
        }

    },
    methods: {
        clickEdit(){
            this.edited=true;
        },
        handleFileUpload(event) {
            this.file = event.target.files[0];
            var input = event.target;
            console.log(input.files);
            if (input.files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.preview = e.target.result;
                    this.edited= false;
                }
                reader.readAsDataURL(input.files[0]);
            }
        },
        getResults() {
            axios.get(this.link + 'api/user', {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                console.log(response)

                this.user = response.data.data.user;
                this.namaEdit = this.user.nama;
                this.nohpEdit = this.user.no_hp;
                this.alamatEdit = this.user.alamat;
                this.emailEdit = this.user.email; // assign response to state user
            })
        },
        editForm() {
            this.tambahdeleteError = false;
            this.tambahdeleteSuccess = false;
            this.tambahSuccess = false;
            this.tambahError = false;
            this.tambahMessage = [];
            var form = new FormData();
            form.append('nama', this.namaEdit);
            form.append('nohp', this.nohpEdit);
            form.append('alamat', this.alamatEdit);
            form.append('email', this.emailEdit);
            if (this.file) {
                form.append('foto', this.file);
            }

            // if (this.tambah) {

            // console.log(this.token);
            axios.post(this.link + 'api/editowner', form, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                // this.tambahSuccess = true;
                this.$toast.show(`Data Berhasil Diupdate`, {
                    position: "top",
                    duration: 1000,
                    max: 1,
                })
                   window.location.reload();
                // this.getResults();
            }).catch(error => {
                // if(){}
                // this.tambahError = true;
                this.$toast.show(`Data Gagal Diupdate`, {
                    type:'error',
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                // if()
                this.editMessage = error.response.data.data.error;
                console.log(this.editMessage);
            })
            // }
        },
        editpw() {
            var form = new FormData();
            form.append('pw_lama', this.pw_lama);
            form.append('pw_baru', this.pw_baru);

            // if (this.tambah) {

            // console.log(this.token);
            axios.post(this.link + 'api/ubahpw', form, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                // this.tambahSuccess = true;
                this.$toast.show(`Password Berhasil Diupdate`, {
                    position: "top",
                    duration: 1000,
                    max: 1,
                })
                this.pw_lama='';
                this.pw_baru='';
            }).catch(error => {
                // if(){}
                // this.tambahError = true;

                if (error.response.data.meta.code == '400') {
                    this.editPw = error.response.data.data.error;
                    this.$toast.show(`Data Gagal Diupdate, cek lagi`, {
                        type: 'error',
                        position: "top",
                        duration: 1000,
                        max: 1,
                    });
                }else{
                    this.$toast.show(error.response.data.data.error, {
                        type: 'error',
                        position: "top",
                        duration: 1000,
                        max: 1,
                    });
                }

                console.log(this.editPw);
            })
        }
    },

}
</script>
